.NavBarItems {
    background-color: #5AE084;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

a {
    color: black;
    text-decoration: none;
}

.NavBar-Logo {
    color: black;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.Nav-Menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.Nav-Links {
    color: black;
    text-decoration: none;
    padding: 00.5rem 1rem;
}

.Nav-Links:hover {
    background-color: #2C944D;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: white;
}

.fa-bars {
    color: white;
}

.Nav-Links-Mobile {
    display: none;
}

.Menu-Icon {
    display: none;
}

@media screen and (max-width: 1100px) {
    .NavBarItems {
        position: relative;
    }

    .Nav-Menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .Nav-Menu.active {
        background: #FAD7B5;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .Nav-Links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .Nav-Links:hover {
        background-color: #2C944D;
        border-radius: 0;
    }

    .NavBar-Logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .Menu-Icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #ffff;
        font-size: 2rem;
    }

    .Nav-Links-Mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: #FAD7B5;
        text-decoration: none;
        color: black;
        font-size: 1.5rem;
    }

    .Nav-Links-Mobile:hover {
        background: #2C944D;
        color: white;
        transition: 250ms;
    }

    button {
        display: none;
    }
}