#resume {
    background-color: white;
}

#appcert {
    width: 30%;
    height: 25%;
}

@media screen and (max-width: 1100px) {
    .flex-container {
        flex-direction: column;
    }
}