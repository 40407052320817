.profile-picture {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: 10px 50px;
}

#profile {
    background-color: #f3efe0;
}

.socialIcon {
    padding: 20px 0px;
}

@media screen and (max-width: 1100px) {
    .flex-container {
        flex-direction: column;
    }
}